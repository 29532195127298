import React, { Component } from "react";
import styled from "styled-components";
import { HeaderFontCss, ParagraphFontCss } from "../Utils/CssStyleTemplateUtil";
import { StaticImage } from "./StaticImage";
import * as Widget from "./Widget";

class ContentSection extends Component {
  render() {
    let { reverse, section } = this.props;
    return (
      <Wrapper reverse={reverse}>
        <div className="container">
          {section.image && (
            <div className="image">
              <StaticImage
                path={"/branding/"}
                name={section.image}
                style={{ height: "100%" }}
              />
            </div>
          )}
          <div className="info" data-delighter>
            <h3 data-delighter>{section.title}</h3>
            <p data-delighter>{section.description}</p>
            {section.cta && (
              <Widget.LinearGradientButton
                data-delighter
                href={section.cta.link}
                target="_blank"
                css="margin-top: 20px;"
              >
                {section.cta.title}
              </Widget.LinearGradientButton>
            )}
          </div>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  & .container {
    display: flex;
    flex-direction: ${props => (props.reverse ? "row-reverse" : "row")};
    justify-content: center;
    & .image {
      width: 60%;
      flex-shrink: 0;
      }
    }
    & .info {
      padding: 60px;
      max-width: 800px;
      width: 100%;

      ${props =>
        props.reverse
          ? "padding-right: 40px; display: flex; flex-direction: column; align-items: flex-end; text-align: right;"
          : "padding-left: 40px;"}
  
      & h3 {
        margin-bottom: 22px;
        font-size: 26px;
        color: #4e4e4e;
        ${HeaderFontCss}
      }

      & p {
        margin-bottom: 30px;
        white-space: pre-wrap;
        font-size: 18px;
        color: #4e4e4e;
        ${ParagraphFontCss}
      }
    }
  }

  /* animation */
  & .container {
    & .info {
      & h3.delighter,
      & p.delighter,
      & .button.delighter {
        transition: all 0.3s ease-out;
        transform: translateY(50%);
        opacity: 0;
      }

      & h3.delighter.started,
      & p.delighter.started {
        transform: none;
        opacity: 1;
        text-align:left;
      }

      & .button.delighter.started {
        transform: none;
        opacity: 1;
      }

      & p.delighter {
        transition-delay: 0.1s;
      }
    }
  }

  @media screen and (max-width: 600px) {
    & .container {
      flex-direction: column;
      align-items: center;
      padding-top: 20px;

      & .image {
        border-left: 0px;
        border-right: 0px;
        width: 70%;
      }
      & .info {
        padding-top: 40px;
        padding-left: 40px;
        padding-right: 40px;

        & h3 {
          text-align: center;
        }

        & p {
          text-align: center;
        }

        & .button {
          margin: 20px auto 0px;
          position: relative;
        }

        & .button:active {
          top: 1px;
          left: 1px;
        }
      }
    }
  }
`;

export default ContentSection;
