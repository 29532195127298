const branding = {
  default: {
    // page config
    websiteTitle: "RevtelTech 網站及系統開發好夥伴",
    metaDescription: "RevtelTech | 規劃/開發/營運 | Web/APP/IoT/Blockchain",
    metaKeywords: "Web開發, App開發, RWD",
    // content
    headerText: "忻旅科技\n專業軟體開發/諮詢\n",
    bannerImg: "banner.jpg",
    quoteImage: "rick.png",
    quoteFromCEO:
      "好的軟體開發與專案執行需要可以信賴的夥伴。唯有透過對專業的信任，才能打出一場出色的戰役並最終贏得市場。",
    sections: [
      {
        title: "忻旅科技以堅實技術做您後盾",
        image: null,
        description:
          "- React Native：曾開發獨角獸電商應用，維護數個開源模組。產出超過 20 個解決方案。\n\n- React：自 SPA 至全靜態網站全涵蓋，導入敏捷式快速交付。產出超過 30 個軟體方案。\n\n- Amazon Cloud：全雲端化服務建構，無痛且快速因應因業務成功而產生的流量變化。\n\n- IoT：硬體 / 韌體 / 雲端 / 應用端一站式規劃，協助客戶做出最適合技術規劃及開發。\n\n- 策略規劃：於多間公司任技術顧問及協助產品規劃，跨領域經驗豐富並具明顯成效。\n",
        cta: null,
      },
      {
        title: "您是否在軟體外包專案遇到以下問題?",
        image: "branding-photo-01.png",
        description:
          "• 功能無法開發，頻頻修改規格\n• 與團隊溝通不順，雙方認知落差大\n• 交期不斷延宕，上線時間無法評估",
        cta: {
          title: "專案健檢",
          link: "https://lin.ee/Qg2AUocY",
        },
      },
      {
        title: "我們認為您值得更好的軟體服務！",
        image: "branding-photo-02.png",
        description:
          "• 技術角度：最精確的可行性評估\n• 成本角度：最有效率的產品佈局\n• 市場角度：MVP打造與迭代規劃",
        cta: {
          title: "專案健檢",
          link: "https://lin.ee/Qg2AUocY",
        },
      },
      {
        title: "顧問服務為您帶來的價值是什麼？",
        image: null,
        description:
          "軟體開發是由大量溝通與協作才能完成的專業工程。除了在成本、時間與技術上取得共識，更重要的是在了解風險與資源限制的情況下做出佈局策略，才能使專案或產品能夠順利推行至市場。專業的顧問服務能在「事前規劃」、「開發執行」及「營運協力」為您創造最大的利益。",
        cta: null,
      },
    ],
  },
  "custom-ec": {
    // page config
    websiteTitle: "RevtelTech 網站及系統開發好夥伴",
    metaDescription: "RevtelTech | 規劃/開發/營運 | Web/APP/IoT/Blockchain",
    metaKeywords: "Web開發, App開發, RWD",
    // content
    headerText: "忻旅科技\n專業軟體開發/全客製電商\n",
    bannerImg: "custom-banner.png",
    quoteImage: "rick.png",
    quoteFromCEO:
      "好的軟體開發與專案執行需要可以信賴的夥伴。唯有透過對專業的信任，才能打出一場出色的戰役並最終贏得市場。",
    sections: [
      {
        title: "模版無法滿足你的，由我們來達成",
        image: null,
        description: null,
        cta: null,
      },
      {
        title: "APP或WEB 隨心所欲",
        image: "branding-photo-01.png",
        description:
          "• APP:以React Native為核心，打造真實上架app電商\n• WEB:以React為主力，電商網站為您專屬客製\n• Server:AWS強力後盾，服務穩定有保障\• 讓我們根據您的需求，選擇最適合您的電商型態與配置",
        cta: {
          title: "為什麼React/React Native是未來優勢",
          link: "https://？？？",
        },
      },
      {
        title: "專注客製，必要之外也給您想要的",
        image: "branding-photo-02.png",
        description:
          "• 從商品特點與商業模型開始，量身打造商品上架方式跟購物頁面顯示方式\n• 以成本及技術角度評估最精確且實際的架構，兼顧日後維護及擴充的可能\n• 真正落實scrum管理模式，讓敏捷團隊為你解決痛點 ",
        cta: {
          title: "我們如何達成？來跟我們聊聊您的需求吧！",
          link: "https://???",
        },
      },
      {
        title: "行銷搭配電商，集客與數據都掌握",
        image: "branding-photo-01.png",
        description:
          "• Google Analytics、SEO、SEM已是基本，數據如何分析與目標設定才是關鍵\n• 想做自己的促銷方式？Revtel可以實現你的想法\n• 折扣碼、購物金、紅利點數靈活運用，舊客新客一手掌握\n",
        cta: null,
      },
      {
        title: "不只是單店，平台也是電商選擇",
        image: "branding-photo-01.png",
        description:
          "• 多店聯合，集合打造平台聚集經濟\n• 各店專屬頁面，展現各店特色商品\n• 不再被平台制約抽成，想要的平台自己打造\n",
        cta: null,
      },
      {
        title: "最基本的功能，還是可以選擇，搭配出最適合的配置",
        image: "branding-photo-01.png",
        description:
          "• FB/Goolge/Apple/Twitter登入\n• 綠界金流/藍新金流串接/電子發票與物流服務\n• 上架IOS/Android",
        cta: null,
      },
    ],
  }
}

module.exports = branding;
exports.default = branding;
