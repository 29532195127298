import React, { Component } from "react";
import styled from "styled-components";
import BrandingData from "../../content/branding-data";
import { HeaderFontCss } from "../Utils/CssStyleTemplateUtil";
import { StaticImage } from "./StaticImage";
import * as Widget from "./Widget";

class Header extends Component {
  render() {
    let data = BrandingData.default;

    return (
      <Wrapper>
        <div className="container">
          <a href="/" target="_blank">
            <div className="logo" href={"http://www.google.com"}>
              <StaticImage
                style={{ height: "100%" }}
                path={"/branding/"}
                name={"revtel-logo-color.png"}
              />
            </div>
          </a>
          <div class="title" style={{ whiteSpace: "pre-wrap" }}>
            {data.headerText || "RevtelTech"}
          </div>
        </div>
        <div className="container cta">
          <Widget.LinearGradientButton
            href={"https://lin.ee/Qg2AUocY"}
            target="_blank"
          >
            免費諮詢
          </Widget.LinearGradientButton>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1187px;
  width: 100%;
  min-height: 50px;
  padding: 10px 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & .container {
    display: flex;
    align-items: center;

    & .logo {
      width: 158px;
      height: 100%;
      margin-right: 38px;
    }

    & .title {
      font-size: 15px;
      color: #4e4e4e;
      padding: 0px;
      ${HeaderFontCss}
    }
  }

  & .cta {
  }

  @media screen and (max-width: 768px) {
    & .container {
      & .title {
        display: none;
      }
    }
  }

  @media screen and (max-width: 600px) {
    & .container {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 500px) {
    & .container {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 350px) {
    & .container {
      & .logo {
        width: 130px;
      }
    }
  }
`;

export default Header;
