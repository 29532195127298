import React, { Component } from "react";
import styled from "styled-components";
import BrandingData from "../../content/branding-data";
import { HeaderFontCss, ParagraphFontCss } from "../Utils/CssStyleTemplateUtil";
import { StaticImage } from "./StaticImage";
import * as Widget from "./Widget";

class TopSection extends Component {
  render() {
    let data = BrandingData.default;

    return (
      <Wrapper>
        <div className="background-wrapper">
          <StaticImage
            style={{ height: "100%" }}
            path={"/branding/"}
            name={data.bannerImg || "banner.jpg"}
          />
        </div>
        <div className="container">
          <div className="item-wrapper" data-delighter>
            <StaticImage
              style={{ maxWidth: 303, width: "100%" }}
              path={"/branding/"}
              name={"web.png"}
            />
            <h3>RWD 響應式網頁設計</h3>
            <p>從形象官網到商業平台，我們都能替您量身訂做。</p>
            <Widget.LinearGradientButton
              href={"https://lin.ee/Qg2AUocY"}
              target="_blank"
            >
              即刻諮詢
            </Widget.LinearGradientButton>
          </div>
          <div className="item-wrapper" data-delighter>
            <StaticImage
              style={{ maxWidth: 303, width: "100%" }}
              path={"/branding/"}
              name={"app.png"}
            />
            <h3>App 行動應用程式</h3>
            <p>提高用戶互動及黏著度，打造驚艷的行動體驗。</p>
            <Widget.LinearGradientButton
              href={"https://lin.ee/Qg2AUocY"}
              target="_blank"
            >
              即刻諮詢
            </Widget.LinearGradientButton>
          </div>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  min-height: 636px;
  height: 100%;

  & .background-wrapper {
    position: absolute;
    width: 100%;
    overflow: hidden;
    height: 100%;
    top: 0px;
  }

  & .container {
    position: relative;
    z-index: 1;
    max-width: 754px;
    padding: 60px 0px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;

    & .item-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      padding: 20px;
      border-radius: 10px;
      background-color: white;
      box-shadow: -1px 5px 9px rgba(0, 0, 0, 0.31);
      width: 45%;

      & h3 {
        font-size: 26px;
        color: #4e4e4e;
        margin-bottom: 10px;
        ${HeaderFontCss}
      }
      & p {
        margin-bottom: 70px;
        text-align: center;
        color: #4e4e4e;
        font-size: 18px;
        flex: 1;
        ${ParagraphFontCss}
      }
    }
  }

  /* animation */
  & .item-wrapper.delighter {
    transition: all 0.3s ease-out;
    transform: translateY(50%);
    opacity: 0;
  }

  & .item-wrapper.delighter:last-child {
    transition-delay: 0.1s;
  }

  & .item-wrapper.delighter.started {
    transform: none;
    opacity: 1;
  }

  @media screen and (max-width: 768px) {
    & .container {
      max-width: 700px;
    }
  }

  @media screen and (max-width: 600px) {
    & .container {
      flex-direction: column;
      align-items: center;

      & .item-wrapper {
        width: 90%;
        max-width: 400px;
      }

      & .item-wrapper:first-child {
        margin-bottom: 20px;
      }
    }
  }
`;

export default TopSection;
