import React, { Component } from "react";
import styled from "styled-components";
import BrandingData from "../../content/branding-data";
import { ParagraphFontCss } from "../Utils/CssStyleTemplateUtil";
import { StaticImage } from "./StaticImage";

class QuotesSection extends Component {
  render() {
    let data = BrandingData.default;

    return (
      <Wrapper>
        <div className="container">
          <div className="image" data-delighter>
            <div>
              <StaticImage path={"/branding/"} name={data.quoteImage} />
            </div>
          </div>

          <div className="info" data-delighter>
            <div className="quote">{data.quoteFromCEO}</div>
            <div className="signature">– Rick Ho, CEO of Revteltech</div>
          </div>
        </div>
      </Wrapper>
    );
  }
}
const Wrapper = styled.div`
  background-color: #f3f3f3;
  padding: 55px 20px;
  min-height: 260px;

  & .container {
    display: flex;
    max-width: 800px;
    margin: 0 auto;
    align-items: flex-start;

    & .image {
      width: 150px;
      height: 150px;
      flex-shrink: 0;
      flex-grow: 0;
      border-radius: 50%;
      padding: 10px;
      margin-right: 40px;
    }

    & .info {
      & .quote {
        font-size: 26px;
        color: #4e4e4e;
        white-space: pre-wrap;
        ${ParagraphFontCss}
      }

      & .signature {
        font-size: 18px;
        color: #4e4e4e;
        font-style: italic;
        text-align: right;
        margin-top: 10px;
        ${ParagraphFontCss}
      }
    }
  }

  /* animation */
  & .container {
    & .image.delighter {
      transition: all 0.3s ease-out;
      transform: translateY(50%);
      opacity: 0;
    }
    & .info.delighter {
      transition: all 0.3s ease-out;
      transform: translateY(50%);
      opacity: 0;
    }

    & .image.delighter.started {
      transform: none;
      opacity: 1;
    }
    & .info.delighter.started {
      transform: none;
      opacity: 1;
    }
  }

  @media screen and (max-width: 600px) {
    & .container {
      flex-direction: column;
      padding: 20px;

      & .image {
        margin-right: 0px;
        margin-bottom: 10px;
      }
    }
  }
`;

export default QuotesSection;
