import React from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import styled from "styled-components";
import Carousel from "nuka-carousel";
import BrandingData from "../../content/branding-data";
import ProjectData from "../../content/project-data";
import { withPage } from "../PageContainer";
import * as Widget from "../Components/Widget";
import * as Icon from "../Components/Icon";
import Delighters from "../Components/ReactDelighters";
import { StaticImage } from "../Components/StaticImage";
import Header from "../Components/BrandingComp.Header";
import TopSection from "../Components/BrandingComp.TopSection";
import ContentSection from "../Components/BrandingComp.ContentSection";
import QuotesSection from "../Components/BrandingComp.QuotesSection";
import ProjectsSection from "../Components/BrandingComp.ProjectsSection";
import BrandingContactForm from "../Components/BrandingContactForm";
import Footer from "../Components/Footer";

class BrandingPage extends React.Component {
  componentWillMount() {
    this._disableLogger(true);
  }

  render() {
    let { data, type } = this.props;

    return (
      <Delighters
        options={{
          attribute: "data-delighter",
          classNames: ["delighter", "started", "ended"],
          start: 0.8, // default start threshold
          end: 0.75, // default end threshold
          autoInit: true, // initialize when DOMContentLoaded
        }}
      >
        <Helmet>
          <title>{data.websiteTitle || "Revtel Tech 忻旅科技"}</title>
          <meta
            name="description"
            content={
              data.metaDescription ||
              "RevtelTech | 規劃/開發/營運 | Web/APP/IoT/Blockchain"
            }
          />
          <meta
            name="keywords"
            content={data.metaKeywords || "App開發, 網頁設計"}
          ></meta>
          <link rel="shortcut icon" href="/images/logo.png" />
        </Helmet>

        <Wrapper>
          <Header />
          <TopSection />

          {data.sections.map((section, idx) => (
            <ContentSection
              key={idx}
              reverse={idx % 2 !== 0}
              section={section}
            />
          ))}

          <QuotesSection />

          <ProjectsSection />

          <FormSection>
            <BrandingContactForm type={type} />
          </FormSection>

          <Footer />
        </Wrapper>
      </Delighters>
    );
  }

  _disableLogger = (showCuteLog = false) => {
    if (showCuteLog) {
      console.log(
        "%cMade with %c❤️ %cby RevtelTech",
        "font-size: 20px; color: #f79145; font-weight:bold;",
        "font-size: 30px; color: red; font-weight:bold;",
        "font-size: 20px; color: #f79145; font-weight:bold;"
      );
    }

    try {
      if (window && typeof window.console !== "undefined") {
        window.console = {};
        window.console.log = () => 0;
        window.console.info = () => 0;
        window.console.warn = () => 0;
        window.console.error = () => 0;
      }
    } catch (err) {
      //
    }
  };
}

const Wrapper = styled.div`
  position: relative;
`;

const FormSection = styled.div`
  background-color: #f5f5f5;
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      data: BrandingData[ownProps.pageContext.type || "default"],
      type: ownProps.pageContext.type || "default",
    }),
    null
  )(BrandingPage)
);
