function template({ title, message = "", formData = {}, css }) {
  return `
    <!DOCTYPE html>
    <html>
      <head>
        <title>${title}</title>
        <meta name="viewport" content="initial-scale=1.0">
        <meta charset="utf-8">
        <style>
        html, body {
            height: 100%;
            margin: 0;
            padding: 0;
            background-color:#f2f2f2;
        }
        
        .wrapper {
            max-width: 600px;
            margin: 0 auto;
            background-color: #ffffff;
        }
        
        .header {
          background-color: #f36a26;
          padding: 10px 0px;
        }
        
        .title {
            font-size: 30px;
            color: #ffffff;
            text-align: center;
            text-transform: capitalize;
        }
        
        .content {
            padding: 20px 30px;
            color: #444;
        }
        
        .message {
            color: #696969;
            font-size: 16px;
            padding: 10px;
            text-align: center;
            margin-bottom: 20px;
        }
        
        .header {
            padding: 10px;
            font-size: 18px;
        }
        
        .footer {
            background-color: grey;
            padding: 10px;
            text-align: center;
            font-size: 16px;
            color: white;
        }

        .footer-text {
          margin: 20px auto;
        }

        .row {
          color: #444;
        }

        .label {
          margin-bottom: 5px;
        }

        .value {
          padding-left: 20px;
          white-space: pre-wrap;
          padding: 5px;
          border: 1px solid lightgrey;
          border-radius: 3px;
          margin-bottom: 5px;
        }
        
        ${css}
        </style>
      </head>
      <body>
        <div class="wrapper">
          <div class="header">
            <h2 class="title">${title}</h2>
          </div>
    
          <div class="content">
            <div class="message">${message}</div>
            <div class="row">
              <div class="label">姓名</div><div class="value">${formData.name}</div>
            </div>
            <div class="row">
              <div class="label">Email/Phone</div><div class="value">${formData["contact-info"]}</div>
            </div>
            <div class="row">
              <div class="label">需求</div><div class="value">${formData.content}</div>
            </div>
          </div>
    
          <div class="footer">
             <div class="footer-text">Revteltech</div>
          </div>
        </div>
      </body>
    </html>
    `;
}

export default template;
